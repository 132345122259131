import { ExternalInteractorBase } from "tsparticles-engine";
import { Remove } from "./Options/Classes/Remove";
export class Remover extends ExternalInteractorBase {
    constructor(container) {
        super(container);
        this.handleClickMode = (mode) => {
            const container = this.container, options = container.actualOptions;
            if (!options.interactivity.modes.remove || mode !== "remove") {
                return;
            }
            const removeNb = options.interactivity.modes.remove.quantity;
            container.particles.removeQuantity(removeNb);
        };
    }
    clear() {
    }
    init() {
    }
    async interact() {
    }
    isEnabled() {
        return true;
    }
    loadModeOptions(options, ...sources) {
        if (!options.remove) {
            options.remove = new Remove();
        }
        for (const source of sources) {
            options.remove.load(source === null || source === void 0 ? void 0 : source.remove);
        }
    }
    reset() {
    }
}
